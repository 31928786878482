.container {
  background-color: #f8f8f8;
  height: 100%;
}
.container .content {
  background-color: #f8f8f8;
  padding-bottom: 1rem;
}
.container .head_right {
  color: #ffffff;
  font-size: 14px;
}
.nav {
  background-color: #ffffff;
  font-family: PingFang SC;
}
.nav .nav_item1 {
  font-size: 14px;
  padding: 12px 33px;
  color: #8b9fad;
  border-bottom: 1px solid #f1f1f1;
}
.nav .nav_item1 .item {
  margin-left: 40px;
}
.nav .nav_item1 .item:first-child {
  margin-left: 0;
}
.nav .nav_item2 {
  font-size: 14px;
  padding: 12px 33px;
  color: #8b9fad;
  border-bottom: 1px solid #f1f1f1;
}
.nav .nav_item2 .item {
  margin-left: 20px;
  text-align: center;
}
.nav .nav_item2 .item:first-child {
  margin-left: 0;
}
.nav .nav_item3 {
  font-size: 14px;
  padding: 12px 33px;
  color: #8b9fad;
  border-bottom: 1px solid #f1f1f1;
}
.nav .nav_item3 .item {
  margin-left: 33px;
  text-align: center;
}
.nav .nav_item3 .item:first-child {
  margin-left: 0;
}
.nav .nav_item4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 12px 34px;
  color: #333333;
}
.nav .nav_item4 .item4_1 {
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 6px 16px 6px 17px;
  text-align: center;
}
.nav .nav_item4 .item4_2 {
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 6px 9px 6px 9px;
  text-align: center;
}
.nav .nav_item4 .item4_3 {
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 6px 10px 6px 11px;
  text-align: center;
}
.nav .nav_item4 .active1 {
  color: #5899f6;
  background-color: #e8f1fe;
  font-weight: 500;
}
.nav .active {
  color: #5899f6;
  font-size: 15px;
  font-weight: 500;
}
.content .teacher_list1 {
  background-color: #ffffff;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  font-family: PingFang SC;
}
.content .teacher_list1 .list-top {
  display: flex;
  justify-content: space-between;
  margin: 0 0.4rem;
}
.content .teacher_list1 .msg_left {
  margin: 10px 0;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content .teacher_list1 .msg_left .img {
  width: 1.2rem;
  height: 1.2rem;
}
.content .teacher_list1 .msg_left .img img {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}
.content .teacher_list1 .msg_left .name {
  font-size: 28rpx;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.content .teacher_list1 .msg_left .VIP {
  display: flex;
  align-items: center;
}
.content .teacher_list1 .msg_left .VIP .img_vip img {
  width: 0.252399rem;
  height: 0.2218rem;
}
.content .teacher_list1 .msg_left .VIP .vip_number {
  margin-left: 0.05rem;
  font-size: 0.24rem;
  color: #333333;
}
.content .teacher_list1 .msg_left .bili {
  margin-top: 0.1rem;
  background-color: #e8f1fe;
  padding: 0.08rem 0.2rem 0.06rem 0.2rem;
  border-radius: 0.08rem;
  font-size: 0.2rem;
  color: #5899f6;
  text-align: center;
}
.content .teacher_list1 .msg_right1 {
  margin-top: 1.04rem;
  margin-left: 0.4rem;
}
.content .teacher_list1 .msg_right1 .top {
  display: flex;
  align-items: center;
}
.content .teacher_list1 .msg_right1 .top .top_left {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .top .top_right {
  margin-left: 0.38rem;
  text-align: left;
}
.content .teacher_list1 .msg_right1 .top .txt {
  text-align: left;
  margin-left: -0.09rem;
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.83);
}
.content .teacher_list1 .msg_right1 .top .left_num {
  color: #5998fc;
  font-size: 0.3rem;
}
.content .teacher_list1 .msg_right1 .top .right_num {
  color: #d98181;
  font-size: 0.3rem;
}
.content .teacher_list1 .msg_right1 .bottom {
  margin-top: 0.76rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .teacher_list1 .msg_right1 .bottom .top_left {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom .top_right {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom .txt {
  text-align: left;
  margin-left: -0.09rem;
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.83);
}
.content .teacher_list1 .msg_right1 .bottom .txt1 {
  margin-top: 0.15rem;
  text-align: left;
  color: #666666;
  font-size: 0.24rem;
}
.content .teacher_list1 .msg_right1 .bottom .left_num {
  color: #5998fc;
  font-size: 0.3rem;
}
.content .teacher_list1 .msg_right1 .bottom .right_num {
  color: #d98181;
  font-size: 0.3rem;
}
.content .teacher_list1 .msg_right1 .bottom1 {
  margin-top: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .teacher_list1 .msg_right1 .bottom1 .top_left {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom1 .top_right {
  margin-left: 0.6rem;
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom1 .txt {
  text-align: left;
  margin-left: -0.09rem;
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.83);
}
.content .teacher_list1 .msg_right1 .bottom1 .txt1 {
  margin-top: 0.15rem;
  text-align: left;
  color: #666666;
  font-size: 0.24rem;
}
.content .teacher_list1 .msg_right1 .bottom1 .left_num {
  color: #5998fc;
  font-size: 0.3rem;
}
.content .teacher_list1 .msg_right1 .bottom1 .right_num {
  color: #d98181;
  font-size: 0.3rem;
}
.content .teacher_list1 .msg_right1 .bottom3 {
  margin-top: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .teacher_list1 .msg_right1 .bottom3 .top_left {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom3 .top_right {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom3 .txt {
  text-align: left;
  margin-left: -0.09rem;
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.83);
}
.content .teacher_list1 .msg_right1 .bottom3 .txt1 {
  margin-top: 0.15rem;
  text-align: left;
  color: #666666;
  font-size: 0.24rem;
}
.content .teacher_list1 .msg_right1 .bottom3 .left_num {
  color: #5998fc;
  font-size: 0.3rem;
}
.content .teacher_list1 .msg_right1 .bottom3 .right_num {
  color: #d98181;
  font-size: 0.3rem;
}
.content .teacher_list1 .bottom_button {
  margin: 0 0.62rem;
  margin-top: 0.2rem;
  padding: 0.32rem 0;
  border-top: 1px solid #cbcbcb;
}
.content .teacher_list1 .bottom_button .button {
  display: flex;
  justify-content: space-between;
}
.content .teacher_list1 .bottom_button .button .stop {
  width: 2.84rem;
  height: 0.75rem;
  line-height: 0.75rem;
  background-color: #d98181;
  color: #ffffff;
  font-size: 0.28rem;
  text-align: center;
  font-weight: 500;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .xiu {
  width: 2.84rem;
  height: 0.75rem;
  line-height: 0.75rem;
  margin-left: 0.38rem;
  background-color: #5899f6;
  color: #ffffff;
  font-size: 0.28rem;
  text-align: center;
  font-weight: 500;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .stop1 {
  width: 2.84rem;
  height: 0.75rem;
  line-height: 0.75rem;
  background-color: #d98181;
  color: #ffffff;
  font-size: 0.28rem;
  text-align: center;
  font-weight: 500;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .xiu1 {
  width: 2.84rem;
  height: 0.75rem;
  line-height: 0.75rem;
  margin-left: 0.38rem;
  background-color: #5899f6;
  color: #ffffff;
  font-size: 0.28rem;
  text-align: center;
  font-weight: 500;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .xiangqing {
  background-color: #5899f6;
  color: #ffffff;
  font-size: 0.28rem;
  text-align: center;
  font-weight: 500;
  padding: 0.18rem 2.65rem;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .left {
  width: 50%;
  text-align: center;
  margin-bottom: 0.38rem;
}
.content .teacher_list1 .bottom_button .button .left .left-top {
  color: #5998fc;
  font-size: 0.3rem;
}
.content .teacher_list1 .bottom_button .button .left .left-bottom {
  color: #666666;
  font-size: 0.24rem;
}
.content .teacher_list1 .bottom_button .button .right {
  width: 50%;
  text-align: center;
  margin-bottom: 0.38rem;
}
.content .teacher_list1 .bottom_button .button .right .left-top {
  color: #5998fc;
  font-size: 0.3rem;
}
.content .teacher_list1 .bottom_button .button .right .left-bottom {
  color: #666666;
  font-size: 0.24rem;
}
.tan {
  width: 5.6rem;
  height: 4.4rem;
  background-image: url(../../assets/imgs/tan_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 3.6rem;
}
.tan .tan_no_txt1 {
  padding: 1.4rem 0.82rem 0 0.72rem;
  display: flex;
  align-items: center;
}
.tan .tan_no_txt1 .tan_no_txt1-left {
  margin-left: 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt1 .tan_no_txt1-right {
  margin-left: 0.3rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt2 {
  margin-top: 0.3rem;
  padding: 0 0.82rem 0 0.72rem;
  display: flex;
  align-items: center;
}
.tan .tan_no_txt2 .tan_no_txt1-left {
  margin-left: 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt2 .tan_no_txt1-right {
  margin-left: 0.3rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt3 {
  padding: 0 0.82rem 0 0.72rem;
  display: flex;
  align-items: center;
}
.tan .tan_no_txt3 .tan_no_txt1-left {
  margin-left: 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_txt3 .tan_no_txt1-right {
  margin-left: 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
}
.tan .tan_no_button {
  margin: 0 auto;
  width: 2.96rem;
  height: 0.58rem;
  line-height: 0.58rem;
  margin-top: 0.24rem;
  text-align: center;
  font-size: 0.28rem;
  color: #ffffff;
  background-color: #5899f6;
  border-radius: 0.16rem;
}
.tan .tishi {
  margin: 0 auto;
  margin-top: 0.1rem;
  color: #d98181;
  font-size: 0.24rem;
  transform: scale(0.8);
  text-align: center;
}
.tan1 {
  width: 5.6rem;
  height: 3.6rem;
  background-image: url(../../assets/imgs/tan_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 3.6rem;
}
.tan1 .tan_no_txt {
  padding: 1.5rem 0.82rem 0 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  text-align: center;
  font-weight: 500;
}
.tan1 .tan_no_txt1 {
  padding: 1.3rem 0.82rem 0 0.72rem;
  font-size: 0.2rem;
  text-align: left;
  font-weight: 500;
  color: #333333;
}
.tan1 .tan_no_txt2 {
  margin-top: 0.05rem;
  color: #333333;
  font-size: 0.16rem;
  transform: scale(0.75);
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  color: #666666;
  text-align: left;
}
.tan1 .tan_no_txt2 .right {
  color: #d98181;
}
.tan1 .input {
  padding: 0 0.7rem 0 0.72rem;
  color: #333333;
  font-weight: bold;
}
.tan1 .input input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  padding: 0.04rem 0;
  font-size: 0.3rem;
}
.tan1 .tan_no_button {
  margin: 0.3rem 1.2rem 0 1.32rem;
  padding: 0.1rem 0.36rem 0.08rem 0.36rem;
  font-size: 0.28rem;
  color: #ffffff;
  background-color: #5899f6;
  text-align: center;
  border-radius: 0.16rem;
}
