































































































































































































































































































































































































































































































.container {
    background-color: #f8f8f8;
    height: 100%;
    // height: calc(100vh + 110px);
    .content {
        background-color: #f8f8f8;
        padding-bottom: 1rem;
    }
    .head_right {
        color: #ffffff;
        font-size: 14px;
    }
}
.nav {
    background-color: #ffffff;
    font-family: PingFang SC;
    .nav_item1 {
        font-size: 14px;
        padding: 12px 33px;
        color: #8b9fad;
        border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 40px;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item2 {
        font-size: 14px;
        padding: 12px 33px;
        color: #8b9fad;
        border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 20px;
            text-align: center;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item3 {
        font-size: 14px;
        padding: 12px 33px;
        color: #8b9fad;
        border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 33px;
            text-align: center;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding: 12px 34px;
        color: #333333;
        .item4_1 {
            border-radius: 4px;
            background-color: #f5f5f5;
            padding: 6px 16px 6px 17px;
            text-align: center;
        }
        .item4_2 {
            border-radius: 4px;
            background-color: #f5f5f5;
            padding: 6px 9px 6px 9px;
            text-align: center;
        }
        .item4_3 {
            border-radius: 4px;
            background-color: #f5f5f5;
            padding: 6px 10px 6px 11px;
            text-align: center;
        }
        .active1 {
            color: #5899f6;
            background-color: #e8f1fe;
            font-weight: 500;
        }
    }
    .active {
        color: #5899f6;
        font-size: 15px;
        font-weight: 500;
    }
}
.content {
    .teacher_list1 {
        background-color: #ffffff;
        margin: 10px;
        border-radius: 8px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
        font-family: PingFang SC;
        .list-top {
            display: flex;
			justify-content: space-between;
            // margin-left: 0.7rem;
			margin: 0 0.4rem;
        }
        .msg_left {
            margin: 10px 0;
			width: 45%;
            // margin-left: 0.8rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .img {
                width: 1.2rem;
                height: 1.2rem;
                img {
                    border-radius: 0.5rem;
                    width: 100%;
                    height: 100%;
                }
            }
            .name {
                font-size: 28rpx;
                font-weight: 500;
                color: #333333;
                text-align: center;
            }
            .VIP {
                display: flex;
                align-items: center;
                .img_vip {
                    // width: 0.24rem;
                    // height: 0.24rem;
                    img {
                        width: 0.252399rem;
                        height: 0.2218rem;
                    }
                }
                .vip_number {
                    margin-left: 0.05rem;
                    font-size: 0.24rem;
                    color: #333333;
                }
            }
            .bili {
                margin-top: 0.1rem;
                background-color: #e8f1fe;
                padding: 0.08rem 0.2rem 0.06rem 0.2rem;
                border-radius: 0.08rem;
                font-size: 0.2rem;
                color: #5899f6;
                text-align: center;
            }
        }
        .msg_right1 {
            margin-top: 1.04rem;
            margin-left: 0.4rem;
            .top {
                display: flex;
                align-items: center;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.38rem;
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    margin-left: -0.09rem;
                    color: #666666;
                    font-size: 0.24rem;
                    transform: scale(0.83);
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
            .bottom {
                margin-top: 0.76rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    margin-left: -0.09rem;
                    color: #666666;
                    font-size: 0.24rem;
                    transform: scale(0.83);
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
            .bottom1 {
                margin-top: 0.16rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.6rem;
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    margin-left: -0.09rem;
                    color: #666666;
                    font-size: 0.24rem;
                    transform: scale(0.83);
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
            .bottom3 {
                margin-top: 0.16rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    margin-left: -0.09rem;
                    color: #666666;
                    font-size: 0.24rem;
                    transform: scale(0.83);
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
        }
        .bottom_button {
            margin: 0 0.62rem;
            margin-top: 0.2rem;
            padding: 0.32rem 0;
            border-top: 1px solid #cbcbcb;
            .button {
                display: flex;
                justify-content: space-between;
                .stop {
                    width: 2.84rem;
                    height: 0.75rem;
                    text-align: center;
                    line-height: 0.75rem;
                    background-color: #d98181;
                    color: #ffffff;
                    font-size: 0.28rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 0.86rem;
                    border-radius: 0.08rem;
                }
                .xiu {
                    width: 2.84rem;
                    height: 0.75rem;
                    text-align: center;
                    line-height: 0.75rem;
                    margin-left: 0.38rem;
                    background-color: #5899f6;
                    color: #ffffff;
                    font-size: 0.28rem;
                    text-align: center;
                    font-weight: 500;
                    border-radius: 0.08rem;
                }
                .stop1 {
                    width: 2.84rem;
                    height: 0.75rem;
                    text-align: center;
                    line-height: 0.75rem;
                    background-color: #d98181;
                    color: #ffffff;
                    font-size: 0.28rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 1.14rem;
                    border-radius: 0.08rem;
                }
                .xiu1 {
                    width: 2.84rem;
                    height: 0.75rem;
                    text-align: center;
                    line-height: 0.75rem;
                    margin-left: 0.38rem;
                    background-color: #5899f6;
                    color: #ffffff;
                    font-size: 0.28rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 1.14rem;
                    border-radius: 0.08rem;
                }
                .xiangqing {
                    background-color: #5899f6;
                    color: #ffffff;
                    font-size: 0.28rem;
                    text-align: center;
                    font-weight: 500;
                    padding: 0.18rem 2.65rem;
                    border-radius: 0.08rem;
                }
                .left {
                    width: 50%;
                    text-align: center;
                    margin-bottom: 0.38rem;
                    .left-top {
                        color: #5998fc;
                        font-size: 0.3rem;
                    }
                    .left-bottom {
                        color: #666666;
                        font-size: 0.24rem;
                    }
                }
                .right {
                    width: 50%;
                    text-align: center;
                    margin-bottom: 0.38rem;
                    .left-top {
                        color: #5998fc;
                        font-size: 0.3rem;
                    }
                    .left-bottom {
                        color: #666666;
                        font-size: 0.24rem;
                    }
                }
            }
        }
    }
}
.tan {
    width: 5.6rem;
    height: 4.4rem;
    background-image: url(../../assets/imgs/tan_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 3.6rem;
    .tan_no_txt1 {
        padding: 1.4rem 0.82rem 0 0.72rem;
        display: flex;
        align-items: center;
        .tan_no_txt1-left {
            margin-left: 0.6rem;
            color: #333333;
            font-size: 0.28rem;
            font-weight: 500;
        }
        .tan_no_txt1-right {
            margin-left: 0.3rem;
            color: #333333;
            font-size: 0.28rem;
            font-weight: 500;
        }
    }
    .tan_no_txt2 {
        margin-top: 0.3rem;
        padding: 0 0.82rem 0 0.72rem;
        display: flex;
        align-items: center;
        .tan_no_txt1-left {
            margin-left: 0.6rem;
            color: #333333;
            font-size: 0.28rem;
            font-weight: 500;
        }
        .tan_no_txt1-right {
            margin-left: 0.3rem;
            color: #333333;
            font-size: 0.28rem;
            font-weight: 500;
        }
    }
    .tan_no_txt3 {
        padding: 0 0.82rem 0 0.72rem;
        display: flex;
        align-items: center;
        .tan_no_txt1-left {
            margin-left: 0.6rem;
            color: #333333;
            font-size: 0.28rem;
            font-weight: 500;
        }
        .tan_no_txt1-right {
            margin-left: 0.6rem;
            color: #333333;
            font-size: 0.28rem;
            font-weight: 500;
        }
    }
    .tan_no_button {
        margin: 0 auto;
        width: 2.96rem;
        height: 0.58rem;
        line-height: 0.58rem;
        margin-top: 0.24rem;
        text-align: center;
        font-size: 0.28rem;
        color: #ffffff;
        background-color: #5899f6;
        border-radius: 0.16rem;
    }
    .tishi {
        margin: 0 auto;
        margin-top: 0.1rem;
        color: #d98181;
        font-size: 0.24rem;
        transform: scale(0.8);
        text-align: center;
    }
}
.tan1 {
    width: 5.6rem;
    height: 3.6rem;
    background-image: url(../../assets/imgs/tan_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 3.6rem;
    .tan_no_txt {
        padding: 1.5rem 0.82rem 0 0.6rem;
        color: #333333;
        font-size: 0.28rem;
        text-align: center;
        font-weight: 500;
    }
    .tan_no_txt1 {
        padding: 1.3rem 0.82rem 0 0.72rem;
        color: #333333;
        font-size: 0.2rem;
        text-align: left;
        font-weight: 500;
        color: #333333;
    }
    .tan_no_txt2 {
        margin-top: 0.05rem;
        color: #333333;
        font-size: 0.16rem;
        transform: scale(0.75);
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        color: #666666;
        text-align: left;
        .right {
            color: #d98181;
        }
    }
    .input {
        padding: 0 0.7rem 0 0.72rem;
        color: #333333;
        font-weight: bold;
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #d8d8d8;
            padding: 0.04rem 0;
            font-size: 0.3rem;
        }
    }
    .tan_no_button {
        margin: 0.3rem 1.2rem 0 1.32rem;
        padding: 0.1rem 0.36rem 0.08rem 0.36rem;
        font-size: 0.28rem;
        color: #ffffff;
        background-color: #5899f6;
        text-align: center;
        border-radius: 0.16rem;
    }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;